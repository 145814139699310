import axios from "axios";
import { URL_BACKEND } from "../../../config";
import { toast } from "react-toastify";

export const getDashboard = () => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_DASHBOARD_IS_LOADING",
      });

      axios.defaults.headers.common["Authorization"] = accessToken;

      const response = await axios.get(`${URL_BACKEND}/dashboard`);

      dispatch({
        type: "GET_DASHBOARD",
        dashboard: response.data.data,
      });

      if (!response.data.data) {
        toast.error("We couldn't find any data source", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch({
        type: "END_DASHBOARD_IS_LOADING",
      });
      console.log(error);
    }
  };
};

export const dashboardInitialState = () => {
  return (dispatch) => {
    dispatch({
      type: "DASHBOARD_INITIAL_STATE",
    });
  };
};
