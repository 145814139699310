export function MissingFieldsMapper(missingFields) {
  const newMissingFields = missingFields.map((data) => {
    return {
      id: {
        value: data.id,
        missingField: null,
        label: "Id",
        attribute: "id",
        type: null,
      },
      lat: {
        value: data.lat,
        missingField: data.lat ? false : true,
        label: "Latitude",
        attribute: "lat",
        type: "text",
      },
      lng: {
        value: data.lng,
        missingField: data.lng ? false : true,
        label: "Longitude",
        attribute: "lng",
        type: "text",
      },
      full_address: {
        value: data.full_address,
        missingField: data.full_address ? false : true,
        label: "Address",
        attribute: "full_address",
        type: "geocode",
      },
      name: {
        value: data.name,
        missingField: data.name ? false : true,
        label: "Name",
        attribute: "name",
        type: "text",
      },
      property_pictures: {
        value: data.property_pictures,
        missingField: data.property_pictures.length ? false : true,
        label: "Pictures",
        attribute: "property_pictures",
        type: "file",
      },
      land_area_sqm: {
        value: data.land_area_sqm,
        missingField: data.land_area_sqm ? false : true,
        label: "Land Area",
        attribute: "land_area_sqm",
        type: "number",
      },
      total_area_sqm: {
        value: data.total_area_sqm,
        missingField: data.total_area_sqm ? false : true,
        label: "Total Area",
        attribute: "total_area_sqm",
        type: "number",
      },
      submarket_id: {
        value: data.submarket_id,
        missingField: data.submarket_id ? false : true,
        label: "Submarket",
        attribute: "submarket_id",
        type: "select",
      },
      sector_id: {
        value: data.sector_id,
        missingField: data.sector_id ? false : true,
        label: "Building Sector",
        attribute: "sector_id",
        type: "select",
      },
      subsector_id: {
        value: data.subsector_id,
        missingField: data.subsector_id ? false : true,
        label: "Building Type",
        attribute: "subsector_id",
        type: "select",
      },
      microsector_id: {
        value: data.microsector_id,
        missingField: data.microsector_id ? false : true,
        label: "Building Subtype",
        attribute: "microsector_id",
        type: "select",
      },
      floors_number: {
        value: data.floors_number ? data.floors_number : "",
        missingField: data.floors_number ? false : true,
        label: "Floors Number",
        attribute: "floors_number",
        type: "number",
      },
      class_id: {
        value: data.class_id,
        missingField: data.class_id ? false : true,
        label: "Class",
        attribute: "class_id",
        type: "select",
      },
      status_id: {
        value: data.status_id,
        missingField: data.status_id ? false : true,
        label: "Status",
        attribute: "status_id",
        type: "select",
      },
    };
  });

  return newMissingFields;
}
