// import files from "../../../@fake-db/uploadFiles/uploadFiles.json";
import axios from "axios";
import { toast } from "react-toastify";
import { URL_BACKEND } from "../../../config";

export const getUploadFiles = () => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_UPLOAD_FILES_IS_LOADING",
      });
      axios.defaults.headers.common["Authorization"] = accessToken;

      const response = await axios.get(`${URL_BACKEND}/documents/`);

      dispatch({
        type: "GET_UPLOAD_FILES",
        files: response.data.data,
      });
      dispatch(restoreFileProgress());
    } catch (error) {
      dispatch({
        type: "END_UPLOAD_FILES_IS_LOADING",
      });
      console.log(error);
    }
  };
};

export const setUploadFile = (data) => {
  return async (dispatch) => {
    dispatch({
      type: "SET_UPLOAD_FILE",
      files: data,
    });
  };
};

export const setUploadProgress = (id, progress) => ({
  type: "SET_UPLOAD_PROGRESS",
  payload: {
    id,
    progress,
  },
});

export const successUploadFile = (id) => ({
  type: "SUCCESS_UPLOAD_FILE",
  payload: id,
});

export const failureUploadFile = (id) => ({
  type: "FAILURE_UPLOAD_FILE",
  payload: id,
});

export const removeFailureFile = (id) => {
  return async (dispatch) => {
    dispatch({
      type: "REMOVE_FAILURE_FILE",
      payload: id,
    });
  };
};

export const uploadFile = (files, type) => (dispatch, getState) => {
  const accessToken = getState().auth.login.token;
  // console.log(type);
  if (files.length) {
    files.forEach(async (file) => {
      const formPayload = new FormData();
      formPayload.append("document", file.file);
      formPayload.append("type_id", type.value);
      try {
        await axios({
          baseURL: `${URL_BACKEND}`,
          url: "/documents",
          method: "post",
          data: formPayload,
          headers: {
            Authorization: accessToken,
            "content-type": "multipart/form-data",
          },
          onUploadProgress: (progress) => {
            const { loaded, total } = progress;
            const percentageProgress = Math.floor((loaded / total) * 100);
            dispatch(setUploadProgress(file.id, percentageProgress));
          },
        });
        dispatch(successUploadFile(file.id));
        dispatch(getUploadFiles());
      } catch (error) {
        dispatch(failureUploadFile(file.id));
      }
    });
  }
};

export const restoreFileProgress = () => {
  return async (dispatch) => {
    dispatch({
      type: "RESTORE_FILE_PROGRESS",
    });
  };
};

export const dismissVisibleAlert = () => {
  return async (dispatch) => {
    dispatch({
      type: "DISMISS_VISIBLE_ALERT",
    });
  };
};

export const deleteData = (id) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_UPLOAD_FILES_IS_LOADING",
      });

      const response = await axios({
        baseURL: `${URL_BACKEND}`,
        url: `/documents/${id}`,
        method: "delete",
        data: id,
        headers: {
          Authorization: accessToken,
        },
      });

      dispatch(getUploadFiles());
      if (response.data.data) {
        toast.success(`File deleted successfully`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch({
        type: "END_UPLOAD_FILES_IS_LOADING",
      });
      toast.error(`File could not be deleted`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };
};

export const UploadFilesInitialState = () => {
  return (dispatch) => {
    dispatch({
      type: "UPLOAD_FILES_INITIAL_STATE",
    });
  };
};
