import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import axios from "axios";
import { logout } from "../../../redux/actions/auth/loginActions";
import * as Icon from "react-feather";
import { connect } from "react-redux";
import notImg from "../../../assets/img/notImg.jpg";
// import { history } from "../../../history";
// import ModalComingSoon from "../../../components/modals/ModalComingSoon";
import { URL_APP } from "../../../config";

const UserDropdown = (props) => {
  // const [toggleModal, setToggleModal] = useState(false);

  const logoutUser = () => {
    props.logout();
  };

  // const redirectDashboard = () => {
  //   window.location.href = "/";
  // };

  // const toggleModalChange = () => {
  //   setToggleModal(!toggleModal);
  // };

  return (
    <DropdownMenu right>
      {/* <DropdownItem tag="a" href="#">
        <Icon.User size={14} className="mr-50" />
        <span className="align-middle" onClick={() => toggleModalChange()}>
          Edit Profile
        </span>

        <ModalComingSoon
          isOpen={toggleModal}
          toggle={toggleModalChange}
          onConfirm={() => redirectDashboard()}
          text="Coming Soon"
        />
      </DropdownItem> */}
      {/* <DropdownItem divider /> */}
      <DropdownItem tag="div" onClick={() => logoutUser()}>
        <Icon.Power size={14} className="mr-50" />
        <span className="align-middle">Log Out</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <li className="d-flex justify-content-center align-items-center">
          <a
            href={URL_APP}
            className="cursor-pointer d-flex align-items-center btn-header-navbar"
          >
            <Icon.Map size={20} className="mr-50 primary" />
            <h6 className="mb-0 font-weight-600 txt-btn-header-navbar">Map</h6>
          </a>
        </li>

        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.user &&
                  this.props.user.firstName + " " + this.props.user.lastName}
              </span>
              <span className="user-status">
                {this.props.user.role && this.props.user.role.name}
              </span>
            </div>
            <span data-tour="user">
              <img
                src={
                  this.props.user.photoUrl ? this.props.user.photoUrl : notImg
                }
                onError={(e) => {
                  e.target.src = notImg;
                }}
                className="round-circle"
                height="40"
                width="40"
                alt="avatar"
              />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.auth.login.user,
  };
};
export default connect(mapStateToProps, {
  logout,
})(NavbarUser);
