import { first } from "lodash";
import { ConfirmLocationsMapper } from "../../mappers/ConfirmLocationsMapper";

const initialState = {
  confirmLocations: [],
  filteredConfirmLocations: [],
  confirmLocationsSelected: {},
  countLocations: 0,
  nextLocations: null,
  nextLocationsLoading: false,
  isLoading: false,
};

const confirmLocations = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONFIRM_LOCATIONS":
      const locations = ConfirmLocationsMapper(action.confirmLocations);

      const totalConfirmLocations = action.idConfirmLocation
        ? state.confirmLocations.filter(
            (location) => location.id.value !== action.idConfirmLocation
          )
        : state.confirmLocations;

      locations.map((item) => {
        return totalConfirmLocations.push(item);
      });

      return {
        ...state,
        confirmLocations: state.nextLocations
          ? totalConfirmLocations
          : locations,
        confirmLocationsSelected: state.nextLocations
          ? first(totalConfirmLocations)
          : locations.length
          ? first(locations)
          : {},
        countLocations: action.countLocations,
        nextLocations: action.nextLocations,
        isLoading: false,
      };

    // case "CONFIRM_LOCATION":
    //   let filterConfirmLocations = state.confirmLocations.filter(
    //     (location) => location.id.value !== action.id
    //   );
    //   return {
    //     ...state,
    //     confirmLocations: filterConfirmLocations,
    //   };

    case "PAGINATION_LOADING":
      return {
        ...state,
        nextLocationsLoading: true,
      };
    case "END_PAGINATION_LOADING":
      return {
        ...state,
        nextLocationsLoading: false,
      };

    case "SEARCH_CONFIRM_LOCATION":
      if (action.val.length) {
        let filteredConfirmLocations = state.confirmLocations.filter(
          (query) => {
            let startsWithCondition =
              query.id.value
                .toString()
                .toLowerCase()
                .startsWith(action.val.toLowerCase()) ||
              query.name.value
                .toLowerCase()
                .startsWith(action.val.toLowerCase()) ||
              (query.class_id.missingField
                ? query.class_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.floors_number.missingField
                ? query.floors_number.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.full_address.missingField
                ? query.full_address.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.land_area_sqm.missingField
                ? query.land_area_sqm.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.lat.missingField
                ? query.lat.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.lng.missingField
                ? query.lng.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.microsector_id.missingField
                ? query.microsector_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.name.missingField
                ? query.name.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.property_pictures.missingField
                ? query.property_pictures.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.sector_id.missingField
                ? query.sector_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.status_id.missingField
                ? query.status_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.submarket_id.missingField
                ? query.submarket_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.subsector_id.missingField
                ? query.subsector_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.total_area_sqm.missingField
                ? query.total_area_sqm.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null);

            let includesCondition =
              query.id.value
                .toString()
                .toLowerCase()
                .includes(action.val.toLowerCase()) ||
              query.name.value
                .toLowerCase()
                .includes(action.val.toLowerCase()) ||
              (query.class_id.missingField
                ? query.class_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.floors_number.missingField
                ? query.floors_number.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.full_address.missingField
                ? query.full_address.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.land_area_sqm.missingField
                ? query.land_area_sqm.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.lat.missingField
                ? query.lat.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.lng.missingField
                ? query.lng.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.microsector_id.missingField
                ? query.microsector_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.name.missingField
                ? query.name.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.property_pictures.missingField
                ? query.property_pictures.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.sector_id.missingField
                ? query.sector_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.status_id.missingField
                ? query.status_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.submarket_id.missingField
                ? query.submarket_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.subsector_id.missingField
                ? query.subsector_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.total_area_sqm.missingField
                ? query.total_area_sqm.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null);

            if (startsWithCondition) {
              return startsWithCondition;
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition;
            } else return null;
          }
        );

        return { ...state, filteredConfirmLocations };
      } else {
        return { ...state };
      }

    case "START_CONFIRM_LOCATIONS_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "END_CONFIRM_LOCATIONS_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };

    case "UPDATE_CONFIRM_LOCATION_SELECT":
      return {
        ...state,
        confirmLocationsSelected: action.confirmLocationsSelected,
      };

    case "UPDATE_CONFIRM_LOCATION_MARKER":
      return {
        ...state,
        confirmLocationsSelected: {
          ...state.confirmLocationsSelected,
          // full_address: {
          //   ...state.confirmLocationsSelected.full_address,
          //   value: action.address,
          // },
          lat: { ...state.confirmLocationsSelected.lat, value: action.lat },
          lng: { ...state.confirmLocationsSelected.lng, value: action.lng },
        },
      };

    case "RESTORE_CONFIRM_LOCATION_SELECTED":
      const location = state.confirmLocations.find(
        (location) => location.id.value === action.id
      );
      return {
        ...state,
        confirmLocationsSelected: location,
      };

    default:
      return state;
  }
};

export default confirmLocations;
