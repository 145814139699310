const initialState = {
  listing: {},
  countListing: 0,
  isListingLoading: false,

  inactiveListing: {},
  countInactiveListing: 0,
  isInactiveListingLoading: false,

  lease: {},
  countLease: 0,
  isLeaseLoading: false,
};

const validationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LISTING_VALIDATION":
      return {
        ...state,
        listing: action.listing,
        countListing: action.countListing,
        isListingLoading: false,
      };
    case "GET_INACTIVE_LISTING_VALIDATION":
      return {
        ...state,
        inactiveListing: action.inactiveListing,
        countInactiveListing: action.countInactiveListing,
        isInactiveListingLoading: false,
      };
    case "RESET_LISTING_VALIDATION":
      return {
        ...state,
        listing: {},
      };
    case "RESET_INACTIVE_LISTING_VALIDATION":
      return {
        ...state,
        inactiveListing: {},
      };
    case "START_LISTING_VALIDATION_IS_LOADING":
      return {
        ...state,
        isListingLoading: true,
      };
    case "START_INACTIVE_LISTING_VALIDATION_IS_LOADING":
      return {
        ...state,
        isInactiveListingLoading: true,
      };
    case "END_LISTING_VALIDATION_IS_LOADING":
      return {
        ...state,
        isListingLoading: false,
      };
    case "END_INACTIVE_LISTING_VALIDATION_IS_LOADING":
      return {
        ...state,
        isInactiveListingLoading: false,
      };
    case "GET_LEASE_VALIDATION":
      return {
        ...state,
        lease: action.lease,
        countLease: action.countLease,
        isLeaseLoading: false,
      };
    case "RESET_LEASE_VALIDATION":
      return {
        ...state,
        lease: {},
      }; 
    case "START_LEASE_VALIDATION_IS_LOADING":
      return {
        ...state,
        isLeaseLoading: true,
      };
    case "END_LEASE_VALIDATION_IS_LOADING":
      return {
        ...state,
        isLeaseLoading: false,
      };
    default:
      return state;
  }
};

export default validationsReducer;
