const initialState = {
  propertyForListing: [],
  isLoadingPropertyForListing: false,
  propertyUnitsForListing: [],
  isLoadingPropertiesUnitsForListing: false,
  unitForListing: {},
  isLoadingUnitForListing: false,

  propertyForInactiveListing: [],
  isLoadingPropertyForInactiveListing: false,
  propertyUnitsForInactiveListing: [],
  isLoadingPropertiesUnitsForInactiveListing: false,
  unitForInactiveListing: {},
  isLoadingUnitForInactiveListing: false,

  propertyForLease: [],
  isLoadingPropertyForLease: false,
  propertyUnitsForLease: [],
  isLoadingPropertiesUnitsForLease: false,
  unitForLease: {},
  isLoadingUnitForLease: false,
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROPERTY_FOR_LISTING":
      return {
        ...state,
        propertyForListing: action.propertyForListing,
        isLoadingPropertyForListing: false,
      }
    case "GET_PROPERTY_FOR_INACTIVE_LISTING":
      return {
        ...state,
        propertyForInactiveListing: action.propertyForInactiveListing,
        isLoadingPropertyForInactiveListing: false,
      }
    case "GET_PROPERTY_UNITS_FOR_LISTING":
      return {
        ...state,
        propertyUnitsForListing: action.propertyUnitsForListing,
        isLoadingPropertiesUnitsForListing: false,
      };
    case "GET_PROPERTY_UNITS_FOR_INACTIVE_LISTING":
      return {
        ...state,
        propertyUnitsForInactiveListing: action.propertyUnitsForInactiveListing,
        isLoadingPropertiesUnitsForInactiveListing: false,
      };
    case "GET_UNIT_FOR_LISTING":
      return {
        ...state,
        unitForListing: action.unitForListing,
        isLoadingUnitForListing: false,
      };
    case "GET_UNIT_FOR_INACTIVE_LISTING":
      return {
        ...state,
        unitForInactiveListing: action.unitForInactiveListing,
        isLoadingUnitForInactiveListing: false,
      };
    case "START_PROPERTY_FOR_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertyForListing: true,
      };
    case "START_PROPERTY_FOR_INACTIVE_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertyForInactiveListing: true,
      };
    case "END_PROPERTY_FOR_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertyForListing: false,
      };
    case "END_PROPERTY_FOR_INACTIVE_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertyForInactiveListing: false,
      };
    case "START_PROPERTY_UNITS_FOR_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesUnitsForListing: true,
      };
    case "START_PROPERTY_UNITS_FOR_INACTIVE_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesUnitsForInactiveListing: true,
      };
    case "END_PROPERTY_UNITS_FOR_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesUnitsForListing: false,
      };
    case "END_PROPERTY_UNITS_FOR_INACTIVE_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesUnitsForInactiveListing: false,
      };
    case "START_UNIT_FOR_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingUnitForListing: true,
      };
    case "START_UNIT_FOR_INACTIVE_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingUnitForInactiveListing: true,
      };
    case "END_UNIT_FOR_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingUnitForListing: false,
      };
    case "END_UNIT_FOR_INACTIVE_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingUnitForInactiveListing: false,
      };





    case "GET_PROPERTY_FOR_LEASE":
      return {
        ...state,
        propertyForLease: action.propertyForLease,
        isLoadingPropertyForLease: false,
      }
    case "GET_PROPERTY_UNITS_FOR_LEASE":
      return {
        ...state,
        propertyUnitsForLease: action.propertyUnitsForLease,
        isLoadingPropertiesUnitsForLease: false,
      };
    case "GET_UNIT_FOR_LEASE":
      return {
        ...state,
        unitForLease: action.unitForLease,
        isLoadingUnitForLease: false,
      };
    case "START_PROPERTY_FOR_LEASE_IS_LOADING":
      return {
        ...state,
        isLoadingPropertyForLease: true,
      };
    case "END_PROPERTY_FOR_LEASE_IS_LOADING":
      return {
        ...state,
        isLoadingPropertyForLease: false,
      };
    case "START_PROPERTY_UNITS_FOR_LEASE_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesUnitsForLease: true,
      };
    case "END_PROPERTY_UNITS_FOR_LEASE_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesUnitsForLease: false,
      };
    case "START_UNIT_FOR_LEASE_IS_LOADING":
      return {
        ...state,
        isLoadingUnitForLease: true,
      };
    case "END_UNIT_FOR_LEASE_IS_LOADING":
      return {
        ...state,
        isLoadingUnitForLease: false,
      };
    default:
      return state;
  }
};

export default propertyReducer;
