import axios from "axios";
import { URL_BACKEND } from "../../../config";
import { toast } from "react-toastify";
// const accessToken = localStorage.getItem("token");

export const getTransactions = (page, perPage, sortBy, sortDirection) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_TRANSACTIONS_IS_LOADING",
      });

      axios.defaults.headers.common["Authorization"] = accessToken;

      const params =
        sortBy || sortDirection
          ? {
              page: page,
              count: perPage,
              sort_by: sortBy,
              order: sortDirection,
            }
          : {
              page: page,
              count: perPage,
            };

      const response = await axios.get(`${URL_BACKEND}/transactions`, {
        params: params,
      });

      dispatch({
        type: "GET_TRANSACTIONS",
        transactions: response.data.data,
        transactionsCount: response.data.pagination.count,
      });
      if (!response.data.data.length) {
        toast.error("We couldn't find any transaction", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch({
        type: "END_TRANSACTIONS_IS_LOADING",
      });
      console.log(error);
    }
  };
};

export const transactionsInitialState = () => {
  return (dispatch) => {
    dispatch({
      type: "TRANSACTIONS_INITIAL_STATE",
    });
  };
};
