const initialState = {
  data: [],
  count: 0,
  roles: [],
  filteredData: [],
  isLoading: false,
  filters: {
    search: "",
  },
};

const usersManagement = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS_MANAGEMENT":
      return {
        ...state,
        data: action.usersManagement,
        count: action.usersManagementCount,
        isLoading: false,
      };

    case "GET_ROLES":
      return {
        ...state,
        roles: action.roles,
        isLoading: false,
      };

    case "FILTER_USERS_MANAGEMENT":
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.payload.search,
        },
    };

    case "START_USERS_MANAGEMENT_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "END_USERS_MANAGEMENT_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };

    case "USERS_MANAGEMENT_INITIAL_STATE":
      return {
        ...state,
        data: [],
        roles: [],
        filteredData: [],
      };

    default:
      return state;
  }
};

export default usersManagement;
