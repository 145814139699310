const initialState = {
  properties: [],
  propertiesCount: 0,
  property: {},
  propertyCreated: false,
  filteredProperties: [],
  isLoading: false,

  propertiesPerPage: [],
  pagination: [],
  currentPage: 1,
  nextProperties: null,
  paginationPropertiesIsLoading: false,

  propertiesMapForListing: [],
  isLoadingPropertiesMapForListing: false,

  propertiesMapForInactiveListing: [],
  isLoadingPropertiesMapForInactiveListing: false,

  propertiesMapForLease: [],
  isLoadingPropertiesMapForLease: false
};

const propertyReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROPERTIES_MAP_FOR_LISTING":
      return {
        ...state,
        propertiesMapForListing: action.propertiesMapForListing,
        isLoadingPropertiesMapForListing: false,
      };
    case "GET_PROPERTIES_MAP_FOR_INACTIVE_LISTING":
      return {
        ...state,
        propertiesMapForInactiveListing: action.propertiesMapForInactiveListing,
        isLoadingPropertiesMapForInactiveListing: false,
      };
    case "START_PROPERTIES_MAP_FOR_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesMapForListing: true,
      };
    case "START_PROPERTIES_MAP_FOR_INACTIVE_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesMapForInactiveListing: true,
      };
    case "END_PROPERTIES_MAP_FOR_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesMapForListing: false,
      };
    case "END_PROPERTIES_MAP_FOR_INACTIVE_LISTING_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesMapForInactiveListing: false,
      };
    case "GET_PROPERTIES_MAP_FOR_LEASE":
      return {
        ...state,
        propertiesMapForLease: action.propertiesMapForLease,
        isLoadingPropertiesMapForLease: false,
      };
    case "START_PROPERTIES_MAP_FOR_LEASE_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesMapForLease: true,
      };
    case "END_PROPERTIES_MAP_FOR_LEASE_IS_LOADING":
      return {
        ...state,
        isLoadingPropertiesMapForLease: false,
      };
    case "GET_PROPERTIES":
      return {
        ...state,
        properties: action.properties,
        propertiesCount: action.propertiesCount,
        isLoading: false,
      };
    case "FILTER_PROPERTIES":
      if (action.val.length) {
        let filteredProperties = state.properties.filter((item) => {
          let startsWithCondition =
            item.id
              .toString()
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.name ? item.name : "")
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.full_address ? item.full_address : "")
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            item.lat.toLowerCase().startsWith(action.val.toLowerCase()) ||
            item.lng.toLowerCase().startsWith(action.val.toLowerCase()) ||
            (item.rentable_area_sqm ? item.rentable_area_sqm : "")
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.land_area_sqm ? item.land_area_sqm : "")
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.market ? (item.market.name ? item.market.name : "") : "")
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.sub_market
              ? item.sub_market.name
                ? item.sub_market.name
                : ""
              : ""
            )
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.micro_market
              ? item.micro_market.name
                ? item.micro_market.name
                : ""
              : ""
            )
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.sectors ? (item.sectors.name ? item.sectors.name : "") : "")
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.sub_sector
              ? item.sub_sector.name
                ? item.sub_sector.name
                : ""
              : ""
            )
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.micro_sector
              ? item.micro_sector.name
                ? item.micro_sector.name
                : ""
              : ""
            )
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.property_status
              ? item.property_status.name
                ? item.property_status.name
                : ""
              : ""
            )
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (item.property_class
              ? item.property_class.name
                ? item.property_class.name
                : ""
              : ""
            )
              .toLowerCase()
              .startsWith(action.val.toLowerCase());

          let includesCondition =
            item.id
              .toString()
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.name ? item.name : "")
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.full_address ? item.full_address : "")
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            item.lat.toLowerCase().includes(action.val.toLowerCase()) ||
            item.lng.toLowerCase().includes(action.val.toLowerCase()) ||
            (item.rentable_area_sqm ? item.rentable_area_sqm : "")
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.land_area_sqm ? item.land_area_sqm : "")
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.market ? (item.market.name ? item.market.name : "") : "")
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.sub_market
              ? item.sub_market.name
                ? item.sub_market.name
                : ""
              : ""
            )
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.micro_market
              ? item.micro_market.name
                ? item.micro_market.name
                : ""
              : ""
            )
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.sectors ? (item.sectors.name ? item.sectors.name : "") : "")
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.sub_sector
              ? item.sub_sector.name
                ? item.sub_sector.name
                : ""
              : ""
            )
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.micro_sector
              ? item.micro_sector.name
                ? item.micro_sector.name
                : ""
              : ""
            )
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.property_status
              ? item.property_status.name
                ? item.property_status.name
                : ""
              : ""
            )
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            (item.property_class
              ? item.property_class.name
                ? item.property_class.name
                : ""
              : ""
            )
              .toLowerCase()
              .includes(action.val.toLowerCase());

          if (startsWithCondition) {
            return startsWithCondition;
          } else if (!startsWithCondition && includesCondition) {
            return includesCondition;
          } else return null;
        });

        return { ...state, filteredProperties };
      } else {
        return { ...state, filteredProperties: [] };
      }
    case "GET_PROPERTY":
      return {
        ...state,
        property: action.property,
        isLoading: false,
      };
    case "CREATE_PROPERTY":
      return {
        ...state,
        propertyCreated: true,
        isLoading: false,
      };
    case "RESTORE_CREATE_PROPERTY":
      return {
        ...state,
        propertyCreated: false,
        isLoading: false,
      };
    case "START_PROPERTIES_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "END_PROPERTIES_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    case "LOAD_NEXT_PROPERTIES":
      var totalProperties = state.properties;
      action.properties.map((prop) => {
        return totalProperties.push(prop);
      });

      var paginationNext = state.pagination;
      paginationNext.push({
        page: state.pagination.length + 1,
        locations: action.properties,
        prevCursor: state.pagination[state.pagination.length - 1].nextCursor,
        nextCursor: action.nextProperties,
      });

      return {
        ...state,
        properties: totalProperties,
        pagination: state.pagination,
        currentPage: action.currentPage,
        propertiesPerPage: action.properties,
        nextProperties: action.nextProperties,
        paginationPropertiesIsLoading: false,
      };
    case "LOAD_PAGE_PROPERTIES":
      return {
        ...state,
        propertiesPerPage: action.properties,
        currentPage: action.currentPage,
        paginationPropertiesIsLoading: false,
      };
    case "PAGINATION_LOADING":
      return {
        ...state,
        paginationPropertiesIsLoading: true,
      };
    case "END_PAGINATION_LOADING":
      return {
        ...state,
        paginationPropertiesIsLoading: false,
      };
    case "PROPERTIES_INITIAL_STATE":
      return {
        ...state,
        properties: [],
        propertiesCount: 0,
        property: {},
        filteredProperties: [],

        propertiesPerPage: [],
        pagination: [],
        currentPage: 1,
        nextProperties: null,
      };
    default:
      return state;
  }
};

export default propertyReducer;
