const initialState = {
  transactions: [],
  transactionsCount: 0,
  isLoading: false,
};

const transactions = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TRANSACTIONS":
      return {
        ...state,
        transactions: action.transactions,
        transactionsCount: action.transactionsCount,
        isLoading: false,
      };

    case "START_TRANSACTIONS_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "END_TRANSACTIONS_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };

    case "TRANSACTIONS_INITIAL_STATE":
      return {
        ...state,
        transactions: [],
      };

    default:
      return state;
  }
};

export default transactions;
