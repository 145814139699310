export function ConfirmLocationsMapper(locations) {
  const newConfirmLocations = locations.map((data) => {
    return {
      id: {
        value: data.id,
        label: "Id",
        attribute: "id",
        type: null,
      },
      lat: {
        value: data.lat ? data.lat : 0,
        label: "Latitude",
        attribute: "lat",
        type: "number",
      },
      lng: {
        value: data.lng ? data.lng : 0,
        label: "Longitude",
        attribute: "lng",
        type: "number",
      },
      name: {
        value: data.name ? data.name : "",
        label: "Name",
        attribute: "name",
        type: null,
      },
      // is_units: {
      //   value: false,
      //   label: "This property is a unit of the building",
      //   attribute: "is_units",
      //   type: "checkbox",
      // },
      full_address: {
        value: data.full_address ? data.full_address : "",
        label: "Address",
        attribute: "full_address",
        type: "geocode",
      },
      listings: data.listings,
      market: {
        value: data.market
          ? {
              value: data.market.id,
              label: data.market.name,
            }
          : "",
        label: "Market",
        attribute: "market",
        type: "select",
      },
      sub_market: {
        value: data.sub_market
          ? {
              value: data.sub_market.id,
              label: data.sub_market.name,
            }
          : "",
        label: "Submarket",
        attribute: "sub_market",
        type: "select",
      },
      micro_market: {
        value: data.micro_market
          ? {
              value: data.micro_market.id,
              label: data.micro_market.name,
            }
          : "",
        label: "Micromarket",
        attribute: "micro_market",
        type: "select",
      },
      sector: {
        value: data.sectors
          ? {
              value: data.sectors.id,
              label: data.sectors.name,
            }
          : "",
        label: "Sector",
        attribute: "sector",
        type: "select",
      },
      sub_sector: {
        value: data.sub_sector
          ? {
              value: data.sub_sector.id,
              label: data.sub_sector.name,
            }
          : "",
        label: "Subsector",
        attribute: "sub_sector",
        type: "select",
      },
      micro_sector: {
        value: data.micro_sector
          ? {
              value: data.micro_sector.id,
              label: data.micro_sector.name,
            }
          : "",
        label: "Microsector",
        attribute: "micro_sector",
        type: "select",
      },
      data_source: {
        value: data.data_sources ? data.data_sources.name : "Not found",
      },
      google_place_id: {
        value: data.google_place_id,
      },
    };
  });

  return newConfirmLocations;
}
