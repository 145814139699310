const initialState = {
  data: [],
  count: 0,
  filteredData: [],
  isLoading: false,
  filters: {
    name: "",
  },
};

const dataSources = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DATA_SOURCES":
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
        isLoading: false,
      };

      case "SET_NAME_FILTER":
        return {
          ...state,
          filters: {
            ...state.filters,
            name: action.payload.search,
          },
      };

    case "START_DATA_SOURCES_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "END_DATA_SOURCES_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default dataSources;
