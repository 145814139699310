import { history } from "../../../history";
import setAuthorizationToken from "./setAuthorizationToken";
import axios from "axios";
import { toast } from "react-toastify";
import { URL_BACKEND } from "../../../config";
import { propertiesInitialState } from "../properties";
import { transactionsInitialState } from "../transactions";
import { dashboardInitialState } from "../dashboard";
import { usersManagementInitialState } from "../usersManagement";
import { UploadFilesInitialState } from "../uploadFiles";

export const login = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${URL_BACKEND}/auth/login`, {
        email: data.email,
        password: data.password,
      });

      const token = "Bearer " + response.data.data.token;

      // SET STORE
      localStorage.setItem("token", token);
      setAuthorizationToken(token);

      dispatch({
        type: "POST_LOGIN",
        token: token,
      });

      const user = await axios.get(`${URL_BACKEND}/profile`);

      // SET STORE
      localStorage.setItem("user", JSON.stringify(user.data.data));

      dispatch({
        type: "GET_USER_ROLE",
        userRole: user.data.data.role.name,
        user: user.data.data,
      });
      return user.data.data;
    } catch (error) {
      console.log(error);
    }
  };
};

export const logout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  history.push("/login");
  return (dispatch) => {
    dispatch({ type: "LOGOUT" });
    dispatch(propertiesInitialState());
    dispatch(transactionsInitialState());
    dispatch(dashboardInitialState());
    dispatch(usersManagementInitialState());
    dispatch(UploadFilesInitialState());
  };
};

export const userIsLoggedIn = () => {
  return !!localStorage.getItem("token");
};

export const updatePassword = (currentPassword, newPassword) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "USER_IS_LOADING",
      });

      const params = {
        currentPassword: currentPassword,
        newPassword: newPassword,
      };

      const response = await axios({
        baseURL: `${URL_BACKEND}`,
        url: "/profile/password",
        method: "put",
        data: params,
        headers: {
          Authorization: accessToken,
        },
      });

      // SET STORE
      localStorage.setItem("user", JSON.stringify(response.data.data));

      toast.success("Password changed successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });

      dispatch({
        type: "GET_USER",
        user: response.data.data,
      });
    } catch (errors) {
      dispatch({
        type: "USER_ERROR",
      });
      console.log(errors);

      toast.error("invalid password", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
    }
  };
};
