export function PropertiesPicturesMapper(locations) {
  const newPropertiesPictures = locations.map((data) => {
    let pictures = [];
    data.property_pictures.map((picture) => {
      return pictures.push({
        id: picture.id,
        src: picture.url,
        width: 1,
        height: 1,
      });
    });

    return {
      id: data.id,
      name: data.name ? data.name : "",
      full_address: data.full_address ? data.full_address : "",
      lat: data.lat ? data.lat : 0,
      lng: data.lng ? data.lng : 0,
      property_pictures: pictures,
    };
  });

  return newPropertiesPictures;
}
