import { modifyFiles } from "../../actions/uploadFiles/utils";
import { toArray } from "lodash";
const initialState = {
  files: [],
  fileProgress: {},
  isVisibleAlert: false,
  isLoading: false,
};

const fileProgressReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_UPLOAD_FILES":
      return {
        ...state,
        files: action.files,
        isLoading: false,
      };

    case "SET_UPLOAD_FILE":
      let alert = false;
      // console.log(toArray(state.fileProgress));
      if (toArray(!state.fileProgress.length)) {
        alert = true;
      }
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          ...modifyFiles(state.fileProgress, action.files),
        },
        isVisibleAlert: alert,
      };

    case "SET_UPLOAD_PROGRESS":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload.id]: {
            ...state.fileProgress[action.payload.id],
            progress: action.payload.progress,
          },
        },
      };

    case "SUCCESS_UPLOAD_FILE":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 1,
          },
        },
      };

    case "FAILURE_UPLOAD_FILE":
      return {
        ...state,
        fileProgress: {
          ...state.fileProgress,
          [action.payload]: {
            ...state.fileProgress[action.payload],
            status: 0,
            progress: 100,
          },
        },
      };

    case "REMOVE_FAILURE_FILE":
      const removeFailureFile = toArray(state.fileProgress).filter(
        (item) => item.id !== action.payload
      );
      return {
        ...state,
        ...{ fileProgress: removeFailureFile },
      };

    case "RESTORE_FILE_PROGRESS":
      return {
        ...state,
        fileProgress: {},
      };

    case "DISMISS_VISIBLE_ALERT":
      return {
        ...state,
        isVisibleAlert: false,
      };

    case "START_UPLOAD_FILES_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "END_UPLOAD_FILES_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };

    case "UPLOAD_FILES_INITIAL_STATE":
      return {
        ...state,
        files: [],
        fileProgress: {},
      };

    default:
      return state;
  }
};

export default fileProgressReducer;
