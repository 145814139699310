export const extendViewport = (bounds) => {
    const deltaLat = 0.0025;
    const deltaLng = 0.005;

    const extendViewport = {
        ne: {
            lat: bounds.ne.lat + deltaLat,
            lng: bounds.ne.lng + deltaLng,
        },
        nw: {
            lat: bounds.nw.lat + deltaLat,
            lng: bounds.nw.lng - deltaLng,
        },
        se: {
            lat: bounds.se.lat - deltaLat,
            lng: bounds.se.lng + deltaLng,
        },
        sw: {
            lat: bounds.sw.lat - deltaLat,
            lng: bounds.sw.lng - deltaLng,
        }
    };
    return extendViewport;
}