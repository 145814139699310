const initialState = {
  data: {},
  filteredData: [],
  isLoading: false,
};

const dataDashboard = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DASHBOARD":
      return {
        ...state,
        data: action.dashboard,
        isLoading: false,
      };

    case "START_DASHBOARD_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "END_DASHBOARD_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };

    case "DASHBOARD_INITIAL_STATE":
      return {
        ...state,
        data: {},
        filteredData: [],
      };

    default:
      return state;
  }
};

export default dataDashboard;
