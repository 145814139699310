import { first } from "lodash";
import { PropertiesPicturesMapper } from "../../mappers/PropertiesPicturesMapper";

const initialState = {
  propertiesPictures: [],
  propertyPicturesSelected: {},
  propertiesPicturesCount: 0,
  nextPropertiesPictures: null,
  nextPropertiesPicturesLoading: false,
  isLoading: false,
};

const confirmPropertiesPictures = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CONFIRM_PROPERTIES_PICTURES":
      const locations = PropertiesPicturesMapper(action.propertiesPictures);

      const totalConfirmLocations = action.idConfirmPropertiesPictures
        ? state.propertiesPictures.filter(
            (location) => location.id !== action.idConfirmPropertiesPictures
          )
        : state.propertiesPictures;

      locations.map((item) => {
        return totalConfirmLocations.push(item);
      });

      return {
        ...state,
        propertiesPictures: state.nextPropertiesPictures
          ? totalConfirmLocations
          : locations,
        propertyPicturesSelected: totalConfirmLocations.length
          ? first(totalConfirmLocations)
          : locations.length
          ? first(locations)
          : {},
        propertiesPicturesCount: action.propertiesPicturesCount
      };

    case "LOAD_NEXT_CURSOR":
      return {
        ...state,
        nextPropertiesPictures: action.nextPropertiesPictures,
        isLoading: false,
      };

    case "PAGINATION_LOADING":
      return {
        ...state,
        nextLocationsLoading: true,
      };
    case "END_PAGINATION_LOADING":
      return {
        ...state,
        nextLocationsLoading: false,
      };

    case "SEARCH_CONFIRM_PROPERTIES_PICTURES":
      if (action.val.length) {
        let filteredConfirmLocations = state.confirmLocations.filter(
          (query) => {
            let startsWithCondition =
              query.id.value
                .toString()
                .toLowerCase()
                .startsWith(action.val.toLowerCase()) ||
              query.name.value
                .toLowerCase()
                .startsWith(action.val.toLowerCase()) ||
              (query.class_id.missingField
                ? query.class_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.floors_number.missingField
                ? query.floors_number.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.full_address.missingField
                ? query.full_address.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.land_area_sqm.missingField
                ? query.land_area_sqm.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.lat.missingField
                ? query.lat.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.lng.missingField
                ? query.lng.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.microsector_id.missingField
                ? query.microsector_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.name.missingField
                ? query.name.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.property_pictures.missingField
                ? query.property_pictures.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.sector_id.missingField
                ? query.sector_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.status_id.missingField
                ? query.status_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.submarket_id.missingField
                ? query.submarket_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.subsector_id.missingField
                ? query.subsector_id.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null) ||
              (query.total_area_sqm.missingField
                ? query.total_area_sqm.label
                    .toLowerCase()
                    .startsWith(action.val.toLowerCase())
                : null);

            let includesCondition =
              query.id.value
                .toString()
                .toLowerCase()
                .includes(action.val.toLowerCase()) ||
              query.name.value
                .toLowerCase()
                .includes(action.val.toLowerCase()) ||
              (query.class_id.missingField
                ? query.class_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.floors_number.missingField
                ? query.floors_number.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.full_address.missingField
                ? query.full_address.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.land_area_sqm.missingField
                ? query.land_area_sqm.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.lat.missingField
                ? query.lat.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.lng.missingField
                ? query.lng.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.microsector_id.missingField
                ? query.microsector_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.name.missingField
                ? query.name.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.property_pictures.missingField
                ? query.property_pictures.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.sector_id.missingField
                ? query.sector_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.status_id.missingField
                ? query.status_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.submarket_id.missingField
                ? query.submarket_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.subsector_id.missingField
                ? query.subsector_id.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null) ||
              (query.total_area_sqm.missingField
                ? query.total_area_sqm.label
                    .toLowerCase()
                    .includes(action.val.toLowerCase())
                : null);

            if (startsWithCondition) {
              return startsWithCondition;
            } else if (!startsWithCondition && includesCondition) {
              return includesCondition;
            } else return null;
          }
        );

        return { ...state, filteredConfirmLocations };
      } else {
        return { ...state };
      }

    case "START_PROPERTIES_PICTURES_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "END_PROPERTIES_PICTURES_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };

    case "UPDATE_CONFIRM_PROPERTY_PICTURES_SELECT":
      return {
        ...state,
        propertyPicturesSelected: action.propertyPicturesSelected,
      };

    default:
      return state;
  }
};

export default confirmPropertiesPictures;
