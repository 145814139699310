const initialState = {
  data: [],
  filteredData: [],
};

const projets = (state = initialState, action) => {
  switch (action.type) {
    case "GET_PROJECTS":
      return {
        ...state,
        data: action.projects,
      };

    case "FILTER_PROJECTS":
      if (action.val.length) {
        let filteredData = state.data.filter((item) => {
          let startsWithCondition =
            item.company.name
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            item.project.name
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            item.project.start_date
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            item.project.end_date
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            item.region.name
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            item.market.name
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            item.lead.name.toLowerCase().startsWith(action.val.toLowerCase());

          let includesCondition =
            item.company.name
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            item.project.name
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            item.project.start_date
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            item.project.end_date
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            item.region.name.toLowerCase().includes(action.val.toLowerCase()) ||
            item.market.name.toLowerCase().includes(action.val.toLowerCase()) ||
            item.lead.name.toLowerCase().includes(action.val.toLowerCase());

          if (startsWithCondition) {
            return startsWithCondition;
          } else if (!startsWithCondition && includesCondition) {
            return includesCondition;
          } else return null;
        });

        return { ...state, filteredData };
      } else {
        return { ...state, filteredData: [] };
      }

    case "CREATE_PROJECT":
      return {
        ...state,
        data: [...state.data, action.addData],
      };

    case "UPDATE_PROJECT":
      return {
        ...state,
        data: action.editData,
      };

    default:
      return state;
  }
};

export default projets;
