const initialState = {
  data: [],
  isLoading: false,
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_REPORTS":
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };

    case "START_REPORTS_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "END_REPORTS_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reportsReducer;
