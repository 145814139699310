import { first } from "lodash";
import { MissingFieldsMapper } from "../../mappers/MissingFieldsMapper";

const initialState = {
  missingFields: [],
  filteredMissingFields: [],
  missingFieldsSelected: {},
};

const missingFields = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MISSING_FIELDS":
      const fields = MissingFieldsMapper(action.missingFields);
      return {
        ...state,
        missingFields: fields,
        missingFieldsSelected: action.missingFields.length ? first(fields) : {},
      };

    case "SEARCH_MISSING_FIELD":
      if (action.val.length) {
        let filteredMissingFields = state.missingFields.filter((query) => {
          let startsWithCondition =
            query.id.value
              .toString()
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            query.name.value
              .toLowerCase()
              .startsWith(action.val.toLowerCase()) ||
            (query.class_id.missingField
              ? query.class_id.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.floors_number.missingField
              ? query.floors_number.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.full_address.missingField
              ? query.full_address.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.land_area_sqm.missingField
              ? query.land_area_sqm.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.lat.missingField
              ? query.lat.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.lng.missingField
              ? query.lng.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.microsector_id.missingField
              ? query.microsector_id.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.name.missingField
              ? query.name.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.property_pictures.missingField
              ? query.property_pictures.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.sector_id.missingField
              ? query.sector_id.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.status_id.missingField
              ? query.status_id.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.submarket_id.missingField
              ? query.submarket_id.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.subsector_id.missingField
              ? query.subsector_id.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null) ||
            (query.total_area_sqm.missingField
              ? query.total_area_sqm.label
                  .toLowerCase()
                  .startsWith(action.val.toLowerCase())
              : null);

          let includesCondition =
            query.id.value
              .toString()
              .toLowerCase()
              .includes(action.val.toLowerCase()) ||
            query.name.value.toLowerCase().includes(action.val.toLowerCase()) ||
            (query.class_id.missingField
              ? query.class_id.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.floors_number.missingField
              ? query.floors_number.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.full_address.missingField
              ? query.full_address.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.land_area_sqm.missingField
              ? query.land_area_sqm.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.lat.missingField
              ? query.lat.label.toLowerCase().includes(action.val.toLowerCase())
              : null) ||
            (query.lng.missingField
              ? query.lng.label.toLowerCase().includes(action.val.toLowerCase())
              : null) ||
            (query.microsector_id.missingField
              ? query.microsector_id.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.name.missingField
              ? query.name.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.property_pictures.missingField
              ? query.property_pictures.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.sector_id.missingField
              ? query.sector_id.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.status_id.missingField
              ? query.status_id.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.submarket_id.missingField
              ? query.submarket_id.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.subsector_id.missingField
              ? query.subsector_id.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null) ||
            (query.total_area_sqm.missingField
              ? query.total_area_sqm.label
                  .toLowerCase()
                  .includes(action.val.toLowerCase())
              : null);

          if (startsWithCondition) {
            return startsWithCondition;
          } else if (!startsWithCondition && includesCondition) {
            return includesCondition;
          } else return null;
        });

        return { ...state, filteredMissingFields };
      } else {
        return { ...state };
      }

    case "UPDATE_MISSING_FIELD_SELECT":
      return {
        ...state,
        missingFieldsSelected: action.missingFieldsSelected,
      };

    case "UPDATE_MISSING_FIELD_MARKER":
      return {
        ...state,
        missingFieldsSelected: {
          ...state.missingFieldsSelected,
          full_address: {
            ...state.missingFieldsSelected.full_address,
            value: action.address,
          },
          lat: { ...state.missingFieldsSelected.lat, value: action.lat },
          lng: { ...state.missingFieldsSelected.lng, value: action.lng },
        },
      };

    default:
      return state;
  }
};

export default missingFields;
