const initialState = {
  token: localStorage.getItem("token") ? localStorage.getItem("token") : "",
  userRole: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).role.name
    : "",
  user: localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user"))
    : "",
};

export const login = (state = initialState, action) => {
  switch (action.type) {
    case "POST_LOGIN": {
      return { ...state, token: action.token };
    }

    case "GET_USER_ROLE": {
      return { ...state, userRole: action.userRole, user: action.user };
    }

    case "LOGOUT": {
      return {
        token: "",
        userRole: "",
        user: "",
      };
    }

    default: {
      return state;
    }
  }
};
