import axios from "axios";
import { URL_BACKEND } from "../../../config";
import { toast } from "react-toastify";
import { usersMapper } from "../../mappers/UsersMapper"
import { filterEmptyDataMapper } from "../../mappers/FilterEmptyDataMapper";

export const getUsersManagement = (page, perPage) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    const search = getState().usersManagement.filters.search;

    try {
      dispatch({
        type: "START_USERS_MANAGEMENT_IS_LOADING",
      });

      axios.defaults.headers.common["Authorization"] = accessToken;


      const url = new URL(`${URL_BACKEND}/users`);
      const urlCount = new URL(`${URL_BACKEND}/users/count`);
      if (page) {
        url.searchParams.set("page", page-1);
      }
      if (perPage) {
        url.searchParams.set("count", perPage);
      }
      if (search) {
        url.searchParams.set("search", search);
        urlCount.searchParams.set("search", search);
      }

      const response = await axios.get(url.toString());
      const responseCount = await axios.get(urlCount.toString());
      dispatch({
        type: "GET_USERS_MANAGEMENT",
        usersManagement: response.data.data,
        usersManagementCount: responseCount.data.data,
      });
      if (!response.data.data.length) {
        toast.error("We couldn't find any user", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch({
        type: "END_USERS_MANAGEMENT_IS_LOADING",
      });
      console.log(error);
    }
  };
};

export const filterUsersManagement = (search) => ({
  type: "FILTER_USERS_MANAGEMENT",
  payload: { search },
});

export const updateStatus = (id, status, page, perPage) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_USERS_MANAGEMENT_IS_LOADING",
      });
      const response = await axios({
        baseURL: `${URL_BACKEND}`,
        url: `/users/${id}`,
        method: "put",
        data: {
          active: status,
        },
        headers: {
          Authorization: accessToken,
        },
      });
      dispatch(getUsersManagement(page, perPage));
      if (response.data.data) {
        toast.success(`User #${id} updated successfully`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch({
        type: "END_USERS_MANAGEMENT_IS_LOADING",
      });
      toast.error(`User could not be updated`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };
};

export const createData = (newData, page, perPage) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_USERS_MANAGEMENT_IS_LOADING",
      });

      axios.defaults.headers.common["Authorization"] = accessToken;

      // Convierte los campos de front al formato que se necesita en back
      const users = usersMapper(newData)
      // Filtra todos los datos que están vacíos ("" || null)
      const newDataFilter = filterEmptyDataMapper(users);

      const response = await axios.post(`${URL_BACKEND}/users`, newDataFilter)

      dispatch(getUsersManagement(page, perPage));
      if (response.data.data) {
        toast.success(`User created`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch({
        type: "END_USERS_MANAGEMENT_IS_LOADING",
      });
      toast.error(`user could not be created`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };
};

export const editData = (updateData, page, perPage) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_USERS_MANAGEMENT_IS_LOADING",
      });

      axios.defaults.headers.common["Authorization"] = accessToken;

      // Convierte los campos de front al formato que se necesita en back
      const users = usersMapper(updateData)
      // Filtra todos los datos que están vacíos ("" || null)
      const newDataFilter = filterEmptyDataMapper(users);
      
      const response = await axios.put(`${URL_BACKEND}/users/${updateData.id}`, newDataFilter)
      dispatch(getUsersManagement(page, perPage));
      if (response.data.data) {
        toast.success(`User #${updateData.id} updated successfully`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch({
        type: "END_USERS_MANAGEMENT_IS_LOADING",
      });
      toast.error(`User could not be updated`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };
};

export const editPassword = (id, newPassword, page, perPage) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_USERS_MANAGEMENT_IS_LOADING",
      });
      axios.defaults.headers.common["Authorization"] = accessToken;

      const response = await axios.put(`${URL_BACKEND}/users/password/${id}`, {newPassword: newPassword})
      dispatch(getUsersManagement(page, perPage));
      if (response.data.data) {
        toast.success(`Password was modified successfully`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch({
        type: "END_USERS_MANAGEMENT_IS_LOADING",
      });
      toast.error(`Error modifying password`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };
};

export const usersManagementInitialState = () => {
  return (dispatch) => {
    dispatch({
      type: "USERS_MANAGEMENT_INITIAL_STATE",
    });
  };
};
