const initialState = {
  isLoadingPropertyPictures: false,
  isLoadingListingPictures: false,
  isLoadingLeasePictures: false,
  isLoadingUnitPictures: false
};

const picturesReducer = (state = initialState, action) => {
  switch (action.type) {
    case "START_PROPERTY_PICTURES_IS_LOADING":
      return {
        ...state,
        isLoadingPropertyPictures: true,
      };
    case "END_PROPERTY_PICTURES_IS_LOADING":
      return {
        ...state,
        isLoadingPropertyPictures: false,
      };
    case "START_LISTING_PICTURES_IS_LOADING":
      return {
        ...state,
        isLoadingListingPictures: true,
      };
    case "END_LISTING_PICTURES_IS_LOADING":
      return {
        ...state,
        isLoadingListingPictures: false,
      };
    case "START_LEASE_PICTURES_IS_LOADING":
      return {
        ...state,
        isLoadingLeasePictures: true,
      };
    case "END_LEASE_PICTURES_IS_LOADING":
      return {
        ...state,
        isLoadingLeasePictures: false,
      };
    case "START_UNIT_PICTURES_IS_LOADING":
      return {
        ...state,
        isLoadingUnitPictures: true,
      };
    case "END_UNIT_PICTURES_IS_LOADING":
      return {
        ...state,
        isLoadingUnitPictures: false,
      };
    default:
      return state;
  }
};

export default picturesReducer;
