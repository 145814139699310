import axios from "axios";
import { toast } from "react-toastify";
import { URL_BACKEND } from "../../../config";

import { extendViewport } from "../../mappers/ExtendViewportMapper";
import { filterViewportMapper } from "../../mappers/FilterViewportMapper";

export const loadPropertiesMapForListing = (viewport) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "START_PROPERTIES_MAP_FOR_LISTING_IS_LOADING",
      });

      const ExtendViewport = extendViewport(viewport);

      let filter = filterViewportMapper(ExtendViewport);
      
      const response = await axios.get(`${URL_BACKEND}/properties/map`, {
        params: filter,
      });

      dispatch({
        type: "GET_PROPERTIES_MAP_FOR_LISTING",
        propertiesMapForListing: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: "END_PROPERTIES_MAP_FOR_LISTING_IS_LOADING",
      });
      console.log(error.response);
    }
  };
};

export const loadPropertiesMapForInactiveListing = (viewport) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "START_PROPERTIES_MAP_FOR_INACTIVE_LISTING_IS_LOADING",
      });

      const ExtendViewport = extendViewport(viewport);

      let filter = filterViewportMapper(ExtendViewport);
      
      const response = await axios.get(`${URL_BACKEND}/properties/map`, {
        params: filter,
      });

      dispatch({
        type: "GET_PROPERTIES_MAP_FOR_INACTIVE_LISTING",
        propertiesMapForInactiveListing: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: "END_PROPERTIES_MAP_FOR_INACTIVE_LISTING_IS_LOADING",
      });
      console.log(error.response);
    }
  };
};

export const loadPropertiesMapForLease = (viewport) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: "START_PROPERTIES_MAP_FOR_LEASE_IS_LOADING",
      });

      const ExtendViewport = extendViewport(viewport);

      let filter = filterViewportMapper(ExtendViewport);
      
      const response = await axios.get(`${URL_BACKEND}/properties/map`, {
        params: filter,
      });

      dispatch({
        type: "GET_PROPERTIES_MAP_FOR_LEASE",
        propertiesMapForLease: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: "END_PROPERTIES_MAP_FOR_LEASE_IS_LOADING",
      });
      console.log(error.response);
    }
  };
};

export const getProperties = (page, perPage, sortBy, sortDirection) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_PROPERTIES_IS_LOADING",
      });

      axios.defaults.headers.common["Authorization"] = accessToken;

      const params =
        sortBy || sortDirection
          ? {
              page: page,
              count: perPage,
              sort_by: sortBy,
              order: sortDirection,
            }
          : {
              page: page,
              count: perPage,
            };

      const response = await axios.get(`${URL_BACKEND}/properties`, {
        params: params,
      });

      dispatch({
        type: "GET_PROPERTIES",
        properties: response.data.data,
        propertiesCount: response.data.pagination.count,
      });
      if (!response.data.data.length) {
        toast.error("We couldn't find any property", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      dispatch({
        type: "END_PROPERTIES_IS_LOADING",
      });
      console.log(error);
    }
  };
};

export const filterProperties = (val) => {
  return (dispatch) => {
    dispatch({
      type: "FILTER_PROPERTIES",
      val,
    });
  };
};

export const getProperty = (id) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_PROPERTIES_IS_LOADING",
      });
      axios.defaults.headers.common["Authorization"] = accessToken;

      const response = await axios.get(`${URL_BACKEND}/properties/${id}`);

      dispatch({
        type: "GET_PROPERTY",
        property: response.data.data,
      });
    } catch (error) {
      dispatch({
        type: "END_PROPERTIES_IS_LOADING",
      });
      console.log(error);
    }
  };
};

export const createData = (newData, isRedAtlas) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_PROPERTIES_IS_LOADING",
      });

      const formPayload = new FormData();
      // console.log(newData.images);
      if (newData.images.length) {
        for (let i = 0; i < newData.images.length; i++) {
          formPayload.append(`pictures`, newData.images[i].file);
        }
      }

      formPayload.append("name", newData.name);
      formPayload.append("full_address", newData.address);
      formPayload.append("lat", newData.lat);
      formPayload.append("lng", newData.lng);
      formPayload.append(
        "rentable_area_sqm",
        newData.rentable_area_sqm ? newData.rentable_area_sqm : null
      );
      formPayload.append(
        "land_area_sqm",
        newData.land_area_sqm ? newData.land_area_sqm * 4047 : null
      ); // conversión de acres a sqm

      if (newData.market ? newData.market.value : newData.market) {
        formPayload.append("market_id", newData.market.value);
      }
      if (newData.sub_market ? newData.sub_market.value : newData.sub_market) {
        formPayload.append("submarket_id", newData.sub_market.value);
      }
      if (
        newData.micro_market ? newData.micro_market.value : newData.micro_market
      ) {
        formPayload.append("micromarket_id", newData.micro_market.value);
      }
      if (newData.sectors ? newData.sectors.value : newData.sectors) {
        formPayload.append("sector_id", newData.sectors.value);
      }
      if (newData.sub_sector ? newData.sub_sector.value : newData.sub_sector) {
        formPayload.append("subsector_id", newData.sub_sector.value);
      }
      if (
        newData.micro_sector ? newData.micro_sector.value : newData.micro_sector
      ) {
        formPayload.append("microsector_id", newData.micro_sector.value);
      }
      if (
        newData.property_status
          ? newData.property_status.value
          : newData.property_status
      ) {
        formPayload.append("status_id", newData.property_status.value);
      }
      if (
        newData.property_class
          ? newData.property_class.value
          : newData.property_class
      ) {
        formPayload.append("class_id", newData.property_class.value);
      }

      // formPayload.append("is_red_atlas", isRedAtlas);

      const response = await axios({
        baseURL: `${URL_BACKEND}`,
        url: `/properties`,
        method: "post",
        data: formPayload,
        headers: {
          Authorization: accessToken,
          "content-type": "multipart/form-data",
        },
      });

      if (response.data.data) {
        toast.success(
          `The property "${newData.name}" was created successfully`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      }
      dispatch({
        type: "CREATE_PROPERTY",
      });
      dispatch({
        type: "RESTORE_CREATE_PROPERTY",
      });
    } catch (error) {
      dispatch({
        type: "END_PROPERTIES_IS_LOADING",
      });
      toast.error(`The property could not be created`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };
};

export const editData = (newData) => {
  return async (dispatch, getState) => {
    const accessToken = getState().auth.login.token;
    try {
      dispatch({
        type: "START_PROPERTIES_IS_LOADING",
      });

      // const formPayload = new FormData();
      // if (newData.images.length) {
      //   for (let i = 0; i < newData.images.length; i++) {
      //     formPayload.append(`pictures`, newData.images[i].file);
      //   }
      // }

      // formPayload.append("name", newData.name);
      // formPayload.append("full_address", newData.address);
      // formPayload.append("lat", newData.lat);
      // formPayload.append("lng", newData.lng);
      // formPayload.append(
      //   "rentable_area_sqm",
      //   newData.rentable_area_sqm ? newData.rentable_area_sqm : null
      // );
      // formPayload.append(
      //   "land_area_sqm",
      //   newData.land_area_sqm ? newData.land_area_sqm : null
      // );

      // if (newData.market ? newData.market.value : newData.market) {
      //   formPayload.append("market_id", newData.market.value);
      // }
      // if (newData.sub_market ? newData.sub_market.value : newData.sub_market) {
      //   formPayload.append("submarket_id", newData.sub_market.value);
      // }
      // if (
      //   newData.micro_market ? newData.micro_market.value : newData.micro_market
      // ) {
      //   formPayload.append("micromarket_id", newData.micro_market.value);
      // }
      // if (newData.sectors ? newData.sectors.value : newData.sectors) {
      //   formPayload.append("sector_id", newData.sectors.value);
      // }
      // if (newData.sub_sector ? newData.sub_sector.value : newData.sub_sector) {
      //   formPayload.append("subsector_id", newData.sub_sector.value);
      // }
      // if (
      //   newData.micro_sector ? newData.micro_sector.value : newData.micro_sector
      // ) {
      //   formPayload.append("microsector_id", newData.micro_sector.value);
      // }
      // if (
      //   newData.property_status
      //     ? newData.property_status.value
      //     : newData.property_status
      // ) {
      //   formPayload.append("status_id", newData.property_status.value);
      // }
      // if (
      //   newData.property_class
      //     ? newData.property_class.value
      //     : newData.property_class
      // ) {
      //   formPayload.append("class_id", newData.property_class.value);
      // }

      const response = await axios({
        baseURL: `${URL_BACKEND}`,
        url: `/properties/${newData.id}`,
        method: "put",
        data: {
          name: newData.name,
          full_address: newData.address,
          lat: newData.lat,
          lng: newData.lng,
          rentable_area_sqm: newData.rentable_area_sqm
            ? newData.rentable_area_sqm
            : null,
          land_area_sqm: newData.land_area_sqm
            ? newData.land_area_sqm * 4047
            : null, // conversión de acres a sqm
          market_id: newData.market ? newData.market.value : null,
          submarket_id: newData.sub_market ? newData.sub_market.value : null,
          micromarket_id: newData.micro_market
            ? newData.micro_market.value
            : null,
          sector_id: newData.sectors ? newData.sectors.value : null,
          subsector_id: newData.sub_sector ? newData.sub_sector.value : null,
          microsector_id: newData.micro_sector
            ? newData.micro_sector.value
            : null,
          status_id: newData.property_status
            ? newData.property_status.value
            : null,
          class_id: newData.property_class
            ? newData.property_class.value
            : null,
        },
        headers: {
          Authorization: accessToken,
          // "content-type": "multipart/form-data",
        },
      });

      if (response.data.data) {
        toast.success(
          `The property "${newData.name}" was updated successfully`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      }
      dispatch(getProperties());
    } catch (error) {
      dispatch({
        type: "END_PROPERTIES_IS_LOADING",
      });
      toast.error(`The property could not be updated`, {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      console.log(error);
    }
  };
};

export const loadNextProperties = (pag) => {
  return async (dispatch, getState) => {
    const nextProperties = getState().properties.nextProperties;

    try {
      dispatch({
        type: "PAGINATION_LOADING",
      });

      const response = await axios.get(`${URL_BACKEND}/properties`, {
        params: { cursor: nextProperties },
      });

      if (nextProperties !== response.data.pagination.next_cursor) {
        dispatch({
          type: "LOAD_NEXT_PROPERTIES",
          properties: response.data.data,
          nextProperties: response.data.pagination.next_cursor,
          currentPage: pag,
        });
      }
    } catch (error) {
      dispatch({
        type: "END_PAGINATION_LOADING",
      });
      console.log(error.response);
    }
  };
};

export const loadPageProperties = (pag) => {
  return async (dispatch, getState) => {
    const pagination = getState().properties.pagination;
    const isPrevPage = pagination.filter((page) => page.page === pag);

    if (!isPrevPage.length) {
      dispatch(loadNextProperties(pag));
    } else if (pag === 1) {
      dispatch(getProperties());
    } else {
      try {
        dispatch({
          type: "PAGINATION_LOADING",
        });

        const response = await axios.get(`${URL_BACKEND}/properties`);

        dispatch({
          type: "LOAD_PAGE_PROPERTIES",
          properties: response.data.data,
          currentPage: pag,
        });
      } catch (error) {
        dispatch({
          type: "END_PAGINATION_LOADING",
        });
        console.log(error.response);
      }
    }
  };
};

export const propertiesInitialState = () => {
  return (dispatch) => {
    dispatch({
      type: "PROPERTIES_INITIAL_STATE",
    });
  };
};
