const initialState = {
  markets: [],
  countries: [],
  micromarkets: [],
  submarkets: [],
  regions: [],
  isLoading: false,
};

const marketReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_MARKETS":
      return {
        ...state,
        markets: action.markets,
        countries: action.countries,
        micromarkets: action.micromarkets,
        submarkets: action.submarkets,
        regions: action.regions,
        isLoading: false,
      };
    case "START_DROPDOWNLISTS_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
};

export default marketReducer;
