const initialState = {
    isLoadingReverseGeocoding: false,
};
  
const googleReducer = (state = initialState, action) => {
switch (action.type) {
    case "START_REVERSE_GEOCODING_IS_LOADING":
    return {
        ...state,
        isLoadingReverseGeocoding: true,
    };
    case "END_REVERSE_GEOCODING_IS_LOADING":
    return {
        ...state,
        isLoadingReverseGeocoding: false,
    };
    default:
    return state;
}
};

export default googleReducer;
  