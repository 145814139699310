const initialState = {
  types: [],
  roles: [],
  industries: [],
  organizationStatus: [],
  propertyStatus: [],
  propertyClasses: [],
  sectors: [],
  subsectors: [],
  microsectors: [],
  documentTypes: [],
  rentIncreaseTypes: [],
  rentIncreaseFrecuencies: [],
  listingTypes: [],
  landSectors: [],
  landTypes: [],
  propertyPositions: [],
  amenities: [],
  listingSectors: [],
  isLoading: false,
};

const dropdownlistsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_DROPDOWN_LISTS":
      return {
        ...state,
        types: action.types,
        roles: action.roles,
        industries: action.industries,
        organizationStatus: action.organizationStatus,
        propertyStatus: action.propertyStatus,
        propertyClasses: action.propertyClasses,
        sectors: action.sectors,
        subsectors: action.subsectors,
        microsectors: action.microsectors,
        documentTypes: action.documentTypes,
        rentIncreaseTypes: action.rentIncreaseTypes,
        rentIncreaseFrecuencies: action.rentIncreaseFrecuencies,
        listingTypes: action.listingTypes,
        landSectors: action.landSectors,
        landTypes: action.landTypes,
        propertyPositions: action.propertyPositions,
        amenities: action.amenities,
        listingSectors: action.listingSectors,
        isLoading: false,
      };

    case "START_DROPDOWNLISTS_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    default:
      return state;
  }
};

export default dropdownlistsReducer;
