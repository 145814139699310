export const usersMapper = (data) => {
  return {
    firstName: data.firstName,
    lastName: data.lastName,
    address: data.address,
    phone: data.phone,
    role: data.role.label,
    active: data.status,
    email: data.email,
    password: data.password,
    countryCode: data.market.code
  };
};
