import React from "react";
import { Home, Users, LogOut, Globe, UserPlus } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSourcetree } from "@fortawesome/free-brands-svg-icons";

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Home size={20} />,
    permissions: [
      "Administrator",
      "Support",
      "Account-Lead",
      "Manager",
      "Broker",
    ],
    navLink: "/",
  },
  {
    id: "dataSources",
    title: "Data Sources",
    type: "item",
    icon: (
      <FontAwesomeIcon
        icon={faSourcetree}
        style={{ fontSize: "18px", marginLTop: "2px" }}
      />
    ),
    permissions: ["Administrator", "Support"],
    navLink: "/dataSources",
  },
  {
    id: "news",
    title: "News",
    type: "item",
    icon: <Globe size={20} />,
    permissions: ["Administrator", "Support"],
    navLink: "/news",
  },
  {
    id: "clients",
    title: "Organizations",
    type: "item",
    icon: <Users size={20} />,
    permissions: ["Administrator", "Support"],
    navLink: "/clients",
  },
  {
    id: "usersManagement",
    title: "Users",
    type: "item",
    icon: <UserPlus size={20} />,
    permissions: [
      "Administrator",
      "Support",
      "Account-Lead",
      "Manager",
      "Broker",
    ],
    navLink: "/usersManagement",
  },
  {
    id: "hr",
    type: "hr",
  },
  {
    id: "logout",
    title: "Log Out",
    type: "item",
    icon: <LogOut size={20} />,
    action: "logout",
  },
];

export default navigationConfig;
