const initialState = {
  data: [],
  companies: [],
  filteredData: [],
  dropdownList: [],
  isLoading: false,
  isLoadingDropdownList: false,
  filters: {
    name: "",
  },
};

const clients = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CLIENTS":
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
        isLoading: false,
      };
    case "GET_CLIENTS_DROPDOWNLIST":
      return {
        ...state,
        dropdownList: action.payload,
        isLoadingDropdownList: false,
      };
    case "GET_COMPANIES":
      return {
        ...state,
        companies: action.companies,
        isLoading: false,
      };

    case "SET_NAME_FILTER_CLIENTS":
      return {
        ...state,
        filters: {
          ...state.filters,
          name: action.payload.search,
        },
    };

    case "START_CLIENTS_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };

    case "END_CLIENTS_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    case "START_CLIENTS_DROPDOWNLIST_IS_LOADING":
      return {
        ...state,
        isLoadingDropdownList: true,
      };

    case "END_CLIENTS_DROPDOWNLIST_IS_LOADING":
      return {
        ...state,
        isLoadingDropdownList: false,
      };
    default:
      return state;
  }
};

export default clients;
