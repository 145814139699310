import { combineReducers } from "redux";
import customizer from "./customizer/";
import auth from "./auth/";
import navbar from "./navbar/Index";
import confirmLocations from "./confirmLocations";
import confirmPropertiesPictures from "./confirmPropertiesPictures";
import missingFields from "./missingFields/";
import dashboard from "./dashboard";
import dataSources from "./dataSources";
import usersManagement from "./usersManagement";
import projects from "./projects";
import news from "./news";
import reports from "./reports";
import clients from "./clients";
import dropdownlists from "./dropdownlists";
import markets from "./markets";
import transactions from "./transactions";
import properties from "./properties/index";
import uploadFiles from "./uploadFiles/index";
import validations from "./validations/index";
import property from "./property/index";
import pictures from "./pictures/index";
import google from "./google/index";

const rootReducer = combineReducers({
  customizer: customizer,
  auth: auth,
  transactions: transactions,
  properties: properties,
  navbar: navbar,
  missingFields: missingFields,
  confirmLocations: confirmLocations,
  confirmPropertiesPictures: confirmPropertiesPictures,
  dataSources: dataSources,
  usersManagement: usersManagement,
  projects: projects,
  news: news,
  reports: reports,
  clients: clients,
  dropdownlists: dropdownlists,
  markets: markets,
  dashboard: dashboard,
  uploadFiles: uploadFiles,
  validations: validations,
  property: property,
  pictures: pictures,
  google: google,
});

export default rootReducer;
