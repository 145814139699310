const initialState = {
  data: [],
  count: 0,
  categories: [],
  isLoading: false,
  filters: {
    search: "",
    reports_research: false,
  },
};

const newsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_NEWS":
      return {
        ...state,
        data: action.payload.data,
        count: action.payload.count,
        isLoading: false,
      };

    case "TOOGLE_REPORT_RESEARCH_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          reports_research: action.payload.reports_research,
        },
      };

    case "SET_SEARCH_FILTER":
      return {
        ...state,
        filters: {
          ...state.filters,
          search: action.payload.search,
        },
      };

    case "GET_NEWS_CATEGORIES":
      return {
        ...state,
        categories: action.categories,
        isLoading: false,
      };
    case "START_NEWS_IS_LOADING":
      return {
        ...state,
        isLoading: true,
      };
    case "END_NEWS_IS_LOADING":
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default newsReducer;
